// src/App.js
import React, { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import SignInPage from './Components/SigninpageComponent';
import HomePageComponent from './Components/HomePageComponent';
import UserTable from './Components/UserlistComponent';
import Posts from './Components/PostComponent';
import Categories from './Components/CategoryComponent';
import Mainpage from './Components/MainpageComponent';
import QnAComponent from './Components/AskedquestionsComponent';
import FeedbackForm from './Components/FeedbackComponent';
import TermsAndConditionsEditor from './Components/Termsandconditions';
import Privacypolicy from './Components/PrivacypolicyComponent';
import Aboutus from './Components/AboutusComponent';
import Howtouse from './Components/HowtouseComponent';
import ContactForm from './Components/ContactdetailsComponent';
import Dashboard from './Components/DashboardComponent';

const ProtectedRoute = ({ element, isAuthenticated }) => {
  console.log('ProtectedRoute: Checking authentication:', isAuthenticated);
  return isAuthenticated ? element : <Navigate to="/" />;
};

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const authState = localStorage.getItem('isAuthenticated') === 'true';
    console.log('App: Initial authentication state:', authState);
    return authState;
  });

  const handleLogin = () => {
    console.log('App: User logged in');
    setIsAuthenticated(true);
    localStorage.setItem('isAuthenticated', 'true'); // Store in local storage
  };

  const handleLogout = () => {
    console.log('App: User logged out');
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated'); // Remove from local storage
  };

  return (
    <Routes>
      <Route path="/" element={<SignInPage onLogin={handleLogin} />} />
      <Route path="/home" element={<HomePageComponent onLogout={handleLogout} />}>
        <Route path="Posts" element={<ProtectedRoute element={<Posts />} isAuthenticated={isAuthenticated} />} />
        <Route path="UserDetails" element={<ProtectedRoute element={<UserTable />} isAuthenticated={isAuthenticated} />} />
        <Route path="Category" element={<ProtectedRoute element={<Categories />} isAuthenticated={isAuthenticated} />} />
        <Route path="Mainpage" element={<ProtectedRoute element={<Mainpage />} isAuthenticated={isAuthenticated} />} />
        <Route path="qna" element={<ProtectedRoute element={<QnAComponent />} isAuthenticated={isAuthenticated} />} />
        <Route path="feedback" element={<ProtectedRoute element={<FeedbackForm />} isAuthenticated={isAuthenticated} />} />
        <Route path="Termsandconditions" element={<ProtectedRoute element={<TermsAndConditionsEditor />} isAuthenticated={isAuthenticated} />} />
        <Route path="Privacypolicy" element={<ProtectedRoute element={<Privacypolicy />} isAuthenticated={isAuthenticated} />} />
        <Route path="Aboutus" element={<ProtectedRoute element={<Aboutus />} isAuthenticated={isAuthenticated} />} />
        <Route path="howtouse" element={<ProtectedRoute element={<Howtouse />} isAuthenticated={isAuthenticated} />} />
        <Route path="Contactdetails" element={<ProtectedRoute element={<ContactForm />} isAuthenticated={isAuthenticated} />} />
        <Route path="Dashboard" element={<ProtectedRoute element={<Dashboard onLogout={handleLogout} />} isAuthenticated={isAuthenticated} />} />
      </Route>
    </Routes>
  );
};

export default App;
